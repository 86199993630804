<template>
  <div class="lg:w-1/2 w-full mb-5">
    <h1 class="text-2xl font-bold">{{ $t("business.owner.title") }}</h1>
    <p class="mt-4">
      {{ $t("business.owner.description") }}
    </p>
  </div>
  <div class="w-full">
    <form
      v-if="showFormBusinessOwner"
      @submit.prevent="businessOwnerFormSubmit"
    >
      <app-alert-error
        v-if="errors['business_id']"
        :message="errors['business_id'][0]"
        class="my-3 lg:w-7/12 w-full bg-red-100 p-3 rounded-md"
      />

      <app-form-input
        id="first_name"
        name="first_name"
        type="text"
        :labelText="$t('business.owner.label_name_of_person')"
        :placeholder="$t('business.owner.first_name')"
        class="lg:w-7/12 w-full"
        v-model="businessOwner.first_name"
        :errorMessage="errors.first_name"
        :disabled="!editMode"
      />

      <app-form-input
        id="last_name"
        name="last_name"
        type="text"
        :placeholder="$t('business.owner.last_name')"
        class="lg:w-7/12 w-full mb-5"
        v-model="businessOwner.last_name"
        :errorMessage="errors.last_name"
        :disabled="!editMode"
      />

      <div class="flex flex-col lg:flex-row lg:w-full w-full lg:justify-between mb-5 flex-initial">
        <app-form-input-with-select
          class="lg:w-1/4"
          v-model="businessOwner.percent_ownership"
          type="number"
          step=".01"
          :labelText="$t('business.owner.label_percent_ownership')"
          :errorMessage="errors.percent_ownership"
          :alignOptionsRight="true"
          :isInputSelect="false"
          optionDesc="%"
          @onblur="onPercentOwnershipFocus = false"
          @onfocus="onPercentOwnershipFocus = true"
          maxLength="5"
        />
        <div
          v-if="onPercentOwnershipFocus"
          class="
            flex-none
            bg-gray-100
            h-full
            rounded-lg
            py-3
            px-2
            text-gray-500
            lg:w-1/4
            w-full
            lg:mr-40
            mr-0
            mt-3
            lg:mt-0
            text-sm
          "
        >
          {{ $t("business.owner.percent_ownership_info") }}
        </div>
      </div>

      <app-form-input
        id="email"
        name="email"
        type="email"
        :labelText="$t('business.owner.label_email')"
        class="lg:w-7/12 w-full my-5"
        v-model="businessOwner.email"
        :errorMessage="errors.email"
        :disabled="!editMode"
      />

      <div class="lg:w-7/12 w-full mb-5">
        <app-form-input
          type="date"
          class="w-full"
          inputclass="rounded-t-md"
          :labelText="$t('business.owner.date_of_birth')"
          :placeholder="$t('business.owner.date_placeholder')"
          v-model="businessOwner.date_of_birth"
          :errorMessage="errors.date_of_birth"
          :disabled="!editMode"
        />
      </div>

      <div class="lg:w-7/12 w-full mb-1">
        <app-form-input
          id="business.owner.address_1"
          name="business.owner.address_1"
          type="text"
          :placeholder="$t('business.owner.address_placeholder_1')"
          :labelText="$t('business.owner.home_address')"
          v-model="businessOwner.address_1"
          :errorMessage="errors.address_1"
          :disabled="!editMode"
        />
      </div>

      <div class="flex flex-col lg:w-7/12 w-full mb-1">
        <app-form-input
          id="business.owner.address_2"
          name="business.owner.address_2"
          type="text"
          :placeholder="$t('business.owner.address_placeholder_2')"
          v-model="businessOwner.address_2"
          :errorMessage="errors.address_2"
          :disabled="!editMode"
        />
      </div>

      <div class="lg:w-7/12 w-full mb-1">
        <app-form-input
          id="business.owner.postcode"
          name="business.owner.postcode"
          type="number"
          :placeholder="$t('business.owner.postcode_placeholder')"
          v-model="businessOwner.postcode"
          :errorMessage="errors.postcode"
          :disabled="!editMode"
          :numberOnly="true"
        />
      </div>

      <div class="lg:w-7/12 w-full mb-1">
        <app-form-input
          id="business.owner.city"
          name="business.owner.city"
          type="text"
          :placeholder="$t('business.owner.city_placeholder')"
          v-model="businessOwner.city"
          :errorMessage="errors.city"
          :disabled="!editMode"
        />
      </div>

      <app-form-country
        :allowFilter="true"
        :showLabel="false"
        :placeholder="$t('general.country')"
        class="lg:w-7/12 w-full mb-1"
        v-model="businessOwner.country"
        :errorMessage="errors.country_id"
        :disabled="!editMode"
      />

      <app-form-state
        :showLabel="false"
        :placeholder="$t('general.address.state')"
        :country_id="businessOwner.country?.id ?? 0"
        class="lg:w-7/12 w-full mb-5"
        v-model="businessOwner.state"
        :errorMessage="errors.state_id"
        :disabled="!editMode"
      />

      <app-form-telephone-no
        name="phone_no"
        :labelText="$t('business.owner.label_phone_no')"
        class="lg:w-7/12 w-full mb-5"
        v-model="businessOwner.phone_no"
        :errorMessage="errors.phone_no"
        :disabled="!editMode"
      />

      <div class="lg:w-7/12 w-full mb-3 text-sm">
        <label class="inline-flex items-center">
          <input
            type="radio"
            class="form-radio focus:ring-transparent"
            @change="onSelected($event)"
            name="business.owner.identification_type"
            value="MyKad"
            v-model="businessOwner.identification_type"
            :disabled="!editMode"
          />
          <span class="ml-2">{{ $t("business.owner.mykad") }}</span>
        </label>
        <label class="inline-flex items-center ml-6">
          <input
            type="radio"
            class="form-radio focus:ring-transparent"
            @change="onSelected($event)"
            name="identification_type"
            value="Passport"
            v-model="businessOwner.identification_type"
            :disabled="!editMode"
          />
          <span class="ml-2">{{ $t("business.owner.passport") }}</span>
        </label>
      </div>

      <div class="flex flex-col lg:w-7/12 w-full mb-5">
        <app-form-input
          id="identification"
          name="identification"
          :labelText="labelIdentification()"
          v-model="businessOwner.identification"
          :errorMessage="errors.identification"
          :type="ic_type_input.type"
          :maxlength="ic_type_input.maxlength"
          :disabled="!editMode"
          :numberOnly="ic_type_input.type == 'number'"
        />
      </div>

      <div class="mt-5">
        <app-upload-support-document
          class="mb-5 lg:w-7/12 w-full"
          v-for="(documents, name) in owner_documents"
          :max="max"
          :key="name"
          :title="name"
          :errors="errors"
          @refresh="refresh"
          @changed="changed"
          @delete="deleteDocument"
          :disabled="!editMode"
          :documents="documents"
          :type="owner_documents_type"
          rule="required"
        />
      </div>

      <app-button
        v-if="editMode"
        type="submit"
        :loading="loading"
        class="mt-8 lg:w-7/12 w-full"
      >
        {{ $t("general.add") }}
      </app-button>

      <app-button-outline
        type="button"
        @onClick="showFormAddBusinessOwner(false)"
        class="mt-3 lg:w-7/12 w-full"
      >
        {{ $t("general.back") }}
      </app-button-outline>
    </form>
    <div v-else>
      <div
        class="border my-3 lg:w-2/3 w-full bg-gray-50"
        v-for="owner in business.owners"
        v-bind:key="owner"
      >
        <div class="relative grid grid-cols-2 gap-4 p-5">
          <div>
            <p class="font-semibold">
              {{ $t("business.owner.label_name_of_person") }}
            </p>
            <p class="text-gray-600">
              {{ owner.first_name }}
              {{ owner.last_name }}
            </p>
          </div>

          <div>
            <p class="font-semibold">
              {{ $t("business.owner.label_percent_ownership") }}
            </p>
            <p class="text-gray-600">
              {{ owner.percent_ownership + "%" }}
            </p>
          </div>

          <div>
            <p class="font-semibold">
              {{ $t("business.owner.label_email") }}
            </p>
            <p class="text-gray-600">
              {{ owner.email }}
            </p>
          </div>

          <div>
            <p class="font-semibold">
              {{ $t("business.owner.date_of_birth") }}
            </p>
            <p class="text-gray-600">
              {{
                owner.date_of_birth
                  ? $moment(String(owner.date_of_birth), "YYYY-MM-DD").format(
                      "DD/MM/YYYY"
                    )
                  : "-"
              }}
            </p>
          </div>

          <div>
            <p class="font-semibold">
              {{ $t("business.owner.label_phone_no") }}
            </p>
            <p class="text-gray-600">{{ owner.phone_no ?? "-" }}</p>
          </div>

          <div>
            <p class="font-semibold">
              {{ owner.identification_type ?? "-" }}
            </p>
            <p class="text-gray-600">{{ owner.identification ?? "-" }}</p>
          </div>

          <div>
            <p class="font-semibold">
              {{ $t("business.owner.home_address") }}
            </p>
            <p class="text-gray-600" v-if="owner.address_1">
              {{ owner.address_1 }}
              <br />
              {{ owner.address_2 }}
              <br />
              {{ owner.postcode }}, {{ owner.city }},
              {{ owner.state?.name ?? "" }}
            </p>
            <p v-else class="text-gray-600">{{ '-' }}</p>
          </div>

          <app-icon
            @click="deleteBusinessOwner(owner?.id)"
            v-if="
              business.business_status_id == BUSINESS_STATUS.CREATED ||
              business.business_status_id == BUSINESS_STATUS.AMENDED
            "
            name="XIcon"
            class="w-5 mr-3 top-5 my-auto right-1 absolute cursor-pointer"
          />
        </div>
        <div v-if="$lodash.isEmpty(owner.media)" class="p-5">
          <p class="uppercase text-xs pb-3">
            {{ $t("business.owner.doc_ic_copy") }}
          </p>
          <div class="pb-3">
            <div
              class="
                py-2
                px-4
                items-start
                justify-between
                bg-gray-100
                border-b border-gray-200 border-t
              "
            >
              <div class="flex-1 flex items-left items-center space-x-5">
                <p class="text-sm">
                  {{ $t("business.owner.no_file_found") }}
                </p>
              </div>
            </div>
          </div>
          <p class="uppercase text-xs pb-3">
            {{ $t("business.owner.doc_ic_copy_back") }}
          </p>
          <div>
            <div
              class="
                py-2
                px-4
                items-start
                justify-between
                bg-gray-100
                border-b border-gray-200 border-t
              "
            >
              <div class="flex-1 flex items-left items-center space-x-5">
                <p class="text-sm">
                  {{ $t("business.owner.no_file_found") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="media in owner.media" v-bind:key="media" class="p-5 mb-5">
            <p class="uppercase text-xs pb-3">
              {{ media.collection_name?.split("_").join(" ") }}
            </p>
            <div
              class="
                py-2
                px-4
                flex flex-col
                sm:flex-row sm:space-x-3
                space-x-0
                sm:space-y-0
                space-y-3
                sm:items-center
                items-start
                justify-between
                hover:bg-gray-100
                border-b border-gray-200 border-t
                w-5/6
              "
            >
              <div class="flex-1 flex items-left items-center space-x-5">
                <app-icon-jpg
                  v-if="
                    media.mime_type.includes('jpeg') ||
                    media.mime_type.includes('jpg')
                  "
                />
                <app-icon-png v-else-if="media.mime_type.includes('png')" />
                <app-icon-pdf v-else-if="media.mime_type.includes('pdf')" />
                <p class="text-sm">
                  {{ media.file_name }}
                </p>
              </div>

              <div class="flex space-x-5">
                <p class="text-xs text-gray-500 mt-1">
                  {{ Math.round(media.size / 1024) }} KB
                </p>
                <a
                  :href="media.original_url"
                  class="flex space-x-1 items-center cursor-pointer text-black"
                  target="_blank"
                >
                  <p class="text-sm">{{ $t("general.view") }}</p>
                </a>
                <a
                  class="flex space-x-1 items-center cursor-pointer text-black"
                  @click="download(media)"
                >
                  <p class="text-sm">{{ $t("general.download") }}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-button-outline
        v-if="editMode"
        class="relative text-gray-700 lg:w-2/3 w-full mt-5"
        @onClick="showFormAddBusinessOwner(true)"
      >
        <app-icon name="PlusIcon" class="absolute w-5 mr-3 left-3" />
        {{
          business.owners?.length > 0
            ? $t("business.owner.add_another_shareholder")
            : $t("business.owner.add_business_shareholder")
        }}
      </app-button-outline>

      <app-button
        type="button"
        :showf70Icon="true"
        v-if="
          business.business_status_id == BUSINESS_STATUS.CREATED ||
          business.business_status_id == BUSINESS_STATUS.AMENDED
        "
        @click="routeTo('/business/support-details')"
        v-show="business.owners?.length > 0"
        class="mt-5 lg:w-2/3 w-full"
      >
        {{ $t("general.continue") }}
      </app-button>
    </div>
  </div>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
import axios from "axios";
export default {
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      businessOwner: {},
      showFormBusinessOwner: false,
      ic_type: "MyKad",
      ic_type_input: null,
      max: 5,
      files: {},
      unsaved: false,
      onPercentOwnershipFocus: false,
      emptyErrors: {
        first_name: [],
        last_name: [],
        phone_no: [],
        identification_type: [],
        identification: [],
        email: [],
        date_of_birth: [],
        address_1: [],
        address_2: [],
        postcode: [],
        city_id: [],
        country_id: [],
        state_id: [],
        percent_ownership: [],
      },
    };
  },
  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    owner_documents() {
      return this.$store.getters["businessStore/owner_documents"];
    },
    owner_documents_type() {
      return this.$store.getters["businessStore/owner_documents_type"];
    },
    errors() {
      const storeErrors = this.$store.getters["businessStore/errors"];
      if (this.$lodash.isEmpty(storeErrors)) {
        return this.emptyErrors;
      }
      return storeErrors;
    },
    editMode() {
      return (
        this.business.business_status_id == BUSINESS_STATUS.CREATED ||
        this.business.business_status_id == BUSINESS_STATUS.AMENDED
      );
    },
  },
  mounted() {
    this.selectInputType();
  },
  beforeCreate() {
    this.$store.dispatch("businessStore/getOwnerDocuments");
    this.$store.dispatch("businessStore/getOwnerDocumentsType");
  },
  methods: {
    routeTo(name) {
      this.$router.push({ path: name });
    },

    onSelected(event) {
      this.ic_type = event.target.value;
      this.businessOwner.identification = null;
      this.selectInputType();
    },
    selectInputType() {
      switch (this.ic_type) {
        case "Passport":
          this.ic_type_input = {
            type: "text",
            maxlength: null,
          };
          break;
        case "MyKad":
        default:
          this.ic_type_input = {
            type: "number",
            maxlength: 12,
          };
      }
    },
    labelIdentification() {
      return this.ic_type == "MyKad"
        ? this.$t("business.owner.mykad")
        : this.$t("business.owner.passport");
    },

    async businessOwnerFormSubmit() {
      this.resetState();
      this.$store.commit("businessStore/setErrorMessage", {
        errors: this.emptyErrors,
        message: null,
      });

      this.businessOwner.country_id = this.businessOwner.country?.id;
      this.businessOwner.state_id = this.businessOwner.state?.id;

      let owner = Object.assign(this.businessOwner, this.files);

      const result = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessOwner",
        owner
      );

      if (result == null) return;

      this.$store.dispatch(
        "businessStore/getBusinessFromSelectedRoleBusinessId"
      );
      this.refresh();
      this.showFormAddBusinessOwner(false);
    },
    async deleteBusinessOwner(businessOwnerId) {
      this.$store.commit("businessStore/setErrorMessage", {
        errors: this.emptyErrors,
        message: null,
      });

      this.businessOwner.id = businessOwnerId;

      const confirm_delete = confirm(this.$t("business.owner.confirm_delete"));

      if (confirm_delete) {
        const result = await this.$store.dispatch(
          "businessStore/deleteBusinessOwner",
          this.businessOwner
        );
        if (result == null) return;
      }

      this.$store.dispatch(
        "businessStore/getBusinessFromSelectedRoleBusinessId"
      );
    },
    showFormAddBusinessOwner(value) {
      this.showFormBusinessOwner = value;
      this.files = {};
      this.businessOwner = {
        identification_type: "MyKad",
        country: {
          id: 160,
          name: "Malaysia",
          icon: process.env.VUE_APP_BACKEND_URL + "/image/flag/mys.svg",
        },
      };
      this.ic_type = this.businessOwner.identification_type;
      this.selectInputType();

      this.$store.commit("businessStore/setErrorMessage", {
        errors: this.emptyErrors,
        message: null,
      });
    },
    changed(file, title) {
      this.unsaved = true;
      Object.assign(this.files, { [title]: file });
    },
    download(media) {
      axios
        .get(media.original_url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: media.mime_type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = media.file_name;
          link.click();
        })
        .catch((error) => {
          // if has been blocked by CORS redirect to original url
          window.location.href = media.original_url;
        });
    },
    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
    refresh() {
      this.$store.dispatch("businessStore/getOwnerDocuments");
      this.$store.dispatch("businessStore/getOwnerDocumentsType");
    },
    async deleteDocument(document) {
      const confirm_delete = confirm(
        this.$t("business.support_documents.delete_confirm")
      );

      if (confirm_delete) {
        const result = await this.$store.dispatch(
          "mediaStore/deleteMedia",
          document._id
        );
      }

      this.refresh();
    },
  },
};
</script>
